import { BehaviorSubject, Observable } from 'rxjs'

import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private isFooter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

  isFooter$: Observable<boolean> = this.isFooter.asObservable()

  private isShadow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  isShadow$: Observable<boolean> = this.isShadow.asObservable()

  setFooter(value: boolean): void {
    this.isFooter.next(value)
  }

  setShadow(value: boolean): void {
    this.isShadow.next(value)
  }
}
